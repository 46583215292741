<template>
  <b-sidebar
    id="add-new-work-area-sidebar"
    :visible="isAddNewWorkAreaSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    width="500"
    @change="(val) => $emit('update:is-add-new-work-area-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- @hidden="resetForm" -->
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          {{ $t('humanResources.workArea.addNew.addWorkArea') }}
        </h5>
        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />
      </div>
      <!-- BODY -->
      <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver"
      >
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >
          <!--Name Work Area field--->
          <validation-provider
            #default="validationContext"
            name="area"
            rules="required"
          >
            <b-form-group
              :label="$t('humanResources.workArea.columns.workArea')"
            >
              <b-form-input
                v-model="WorkAreaData.nombre"
                :state="getValidationState(validationContext)"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <!--description field--->
          <validation-provider
            #default="validationContext"
            name="Description"
            rules="required"
          >
            <b-form-group
              :label="$t('humanResources.workArea.columns.description')"
            >
              <b-form-input
                v-model="WorkAreaData.descripcion"
                :state="getValidationState(validationContext)"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <!--departament select--->
          <validation-provider
            #default=" { errors } "
            name="departament"
            rules="required"
          >
            <b-form-group
              :label="$t('humanResources.workArea.columns.departament')"
              :state="errors.length > 0 ? false:null"
            >
              <v-select
                v-model="WorkAreaData.departamentoId"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :clearable="false"
                label="texto"
                :reduce="option => option.id"
                :options="departamentsOptions"
              />
              <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                {{ errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <!--Area manager select--->
          <validation-provider
            #default="{ errors }"
            name="Area manager"
            rules="required"
          >
            <b-form-group
              :label="$t('humanResources.workArea.columns.areaManager')"
              :state="errors.length > 0 ? false:null"
            >
              <v-select
                v-model="WorkAreaData.empleadoJefeId"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :clearable="false"
                label="texto"
                :reduce="option => option.id"
                :options="employees"
              />
              <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                {{ errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
            >
              {{ $t('Lists.Add') }}
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              {{ $t('Lists.Cancel') }}
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar,
  BForm,
  BFormGroup,
  BButton,
  BFormInput,
  BFormInvalidFeedback,
} from 'bootstrap-vue'
import {
  ValidationObserver,
  ValidationProvider,
} from 'vee-validate'
import {
  required,
} from '@validations'
import vSelect from 'vue-select'
import { ref } from '@vue/composition-api'
import formValidation from '@core/comp-functions/forms/form-validation'
import humanResources from '@/services/humanresources.service'

export default {
  components: {
    BSidebar,
    ValidationObserver,
    BForm,
    BFormGroup,
    BButton,
    BFormInput,
    BFormInvalidFeedback,
    ValidationProvider,
    vSelect,
  },
  props: {
    isAddNewWorkAreaSidebarActive: {
      type: Boolean,
      required: true,
    },
  },
  setup(props, { emit }) {
    /* Services */
    const {
      createWorkArea,
      fetchDepartamentsOptions,
      fetchEmployesOptions,
    } = humanResources()
    /* Data */
    const blankWorkAreaData = {
      areaTrabajoId: null,
      nombre: '',
      descripcion: '',
      empleadoJefeId: null,
      departamentoId: null,
    }
    const WorkAreaData = ref(JSON.parse(JSON.stringify(blankWorkAreaData)))
    const resetClassificationData = () => {
      WorkAreaData.value = JSON.parse(JSON.stringify(blankWorkAreaData))
    }
    const departamentsOptions = ref([])
    const employees = ref([])

    fetchDepartamentsOptions(data => {
      departamentsOptions.value = data
    })
    fetchEmployesOptions(data => {
      employees.value = data
    })
    /* Events */
    const onSubmit = () => {
      createWorkArea(WorkAreaData.value)
        .then(() => {
          emit('update:is-add-new-work-area-sidebar-active', false)
          emit('refresh-data')
        })
    }

    /* Validations */
    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetClassificationData)

    return {
      // Data
      WorkAreaData,
      departamentsOptions,
      employees,
      // Events
      onSubmit,
      // Validations
      required,
      getValidationState,
      resetForm,
      refFormObserver,
    }
  },
}
</script>

<style>

</style>
