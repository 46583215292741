var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-sidebar',{attrs:{"id":"add-new-work-area-sidebar","visible":_vm.isAddNewWorkAreaSidebarActive,"bg-variant":"white","sidebar-class":"sidebar-lg","shadow":"","backdrop":"","no-header":"","right":"","width":"500"},on:{"change":function (val) { return _vm.$emit('update:is-add-new-work-area-sidebar-active', val); }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hide = ref.hide;
return [_c('div',{staticClass:"d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"},[_c('h5',{staticClass:"mb-0"},[_vm._v(" "+_vm._s(_vm.$t('humanResources.workArea.addNew.addWorkArea'))+" ")]),_c('feather-icon',{staticClass:"ml-1 cursor-pointer",attrs:{"icon":"XIcon","size":"16"},on:{"click":hide}})],1),_c('validation-observer',{ref:"refFormObserver",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('b-form',{staticClass:"p-2",on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.onSubmit)},"reset":function($event){$event.preventDefault();return _vm.resetForm($event)}}},[_c('validation-provider',{attrs:{"name":"area","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(validationContext){return [_c('b-form-group',{attrs:{"label":_vm.$t('humanResources.workArea.columns.workArea')}},[_c('b-form-input',{attrs:{"state":_vm.getValidationState(validationContext)},model:{value:(_vm.WorkAreaData.nombre),callback:function ($$v) {_vm.$set(_vm.WorkAreaData, "nombre", $$v)},expression:"WorkAreaData.nombre"}}),_c('b-form-invalid-feedback',[_vm._v(" "+_vm._s(validationContext.errors[0])+" ")])],1)]}}],null,true)}),_c('validation-provider',{attrs:{"name":"Description","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(validationContext){return [_c('b-form-group',{attrs:{"label":_vm.$t('humanResources.workArea.columns.description')}},[_c('b-form-input',{attrs:{"state":_vm.getValidationState(validationContext)},model:{value:(_vm.WorkAreaData.descripcion),callback:function ($$v) {_vm.$set(_vm.WorkAreaData, "descripcion", $$v)},expression:"WorkAreaData.descripcion"}}),_c('b-form-invalid-feedback',[_vm._v(" "+_vm._s(validationContext.errors[0])+" ")])],1)]}}],null,true)}),_c('validation-provider',{attrs:{"name":"departament","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function( ref ){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('humanResources.workArea.columns.departament'),"state":errors.length > 0 ? false:null}},[_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"clearable":false,"label":"texto","reduce":function (option) { return option.id; },"options":_vm.departamentsOptions},model:{value:(_vm.WorkAreaData.departamentoId),callback:function ($$v) {_vm.$set(_vm.WorkAreaData, "departamentoId", $$v)},expression:"WorkAreaData.departamentoId"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false:null}},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}],null,true)}),_c('validation-provider',{attrs:{"name":"Area manager","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('humanResources.workArea.columns.areaManager'),"state":errors.length > 0 ? false:null}},[_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"clearable":false,"label":"texto","reduce":function (option) { return option.id; },"options":_vm.employees},model:{value:(_vm.WorkAreaData.empleadoJefeId),callback:function ($$v) {_vm.$set(_vm.WorkAreaData, "empleadoJefeId", $$v)},expression:"WorkAreaData.empleadoJefeId"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false:null}},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}],null,true)}),_c('div',{staticClass:"d-flex mt-2"},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"mr-2",attrs:{"variant":"primary","type":"submit"}},[_vm._v(" "+_vm._s(_vm.$t('Lists.Add'))+" ")]),_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(186, 191, 199, 0.15)'),expression:"'rgba(186, 191, 199, 0.15)'",modifiers:{"400":true}}],attrs:{"type":"button","variant":"outline-secondary"},on:{"click":hide}},[_vm._v(" "+_vm._s(_vm.$t('Lists.Cancel'))+" ")])],1)],1)]}}],null,true)})]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }