<template>
  <div>
    <work-area-add-new
      :is-add-new-work-area-sidebar-active.sync="isAddNewWorkAreaSidebarActive"
      @refresh-data="() => { refWorkAreaList.refreshData() }"
    />
    <list
      ref="refWorkAreaList"
      sort-by="areaTrabajoId"
      :refetch-records="fetchWorkAreas"
      refetch-records-name="areasTrabajo"
      :actions="actions"
      key-field="areaTrabajoId"
      :table-columns="tableColumns"
      :table-actions="tableActions"
      :delete-method="deleteWorkArea"
      :filters.sync="filters"
    />
  </div>
</template>
<script>
import { ref } from '@vue/composition-api'
import humanResources from '@/services/humanresources.service'
import i18n from '@/libs/i18n'
import List from '@/components/List.vue'
import WorkAreaAddNew from './WorkAreaAddNew.vue'

export default {
  components: {
    WorkAreaAddNew,
    List,

  },
  setup() {
    /* Refs */
    const refWorkAreaList = ref(null)
    const isAddNewWorkAreaSidebarActive = ref(false)
    /* Services */
    const { fetchWorkAreas, fetchWorkArea, deleteWorkArea } = humanResources()
    const tableColumns = [
      {
        label: i18n.t('humanResources.workArea.columns.workArea'),
        key: 'nombre',
      },
      {
        label: i18n.t('humanResources.workArea.columns.description'),
        key: 'descripcion',
      },
      {
        label: i18n.t('humanResources.workArea.columns.departament'),
        key: 'departamento.nombre',
      },
      {
        label: i18n.t('humanResources.workArea.columns.areaManager'),
        key: 'empleadoJefe.nombreEmpleado',
      },
      {
        label: i18n.t('Lists.Actions'),
        key: 'actions',
      },
    ]
    const tableActions = ref([
      {
        name: 'edit',
        label: i18n.t('Lists.Edit'),
        aclAction: 'create',
        aclResource: 'areaTrabajo',
        routeName: 'apps-human-resources-work-area-edit',
        params: ['areaTrabajoId'],
        icon: 'CodepenIcon',
      },
      {
        name: 'delete',
        label: i18n.t('Lists.Delete'),
        aclAction: 'delete',
        aclResource: 'areaTrabajo',
        params: ['areaTrabajoId'],
        icon: 'TrashIcon',
      },
    ])
    const actions = ref([
      {
        label: i18n.t('humanResources.workArea.list.newWorkArea'),
        aclAction: 'create',
        click: () => { isAddNewWorkAreaSidebarActive.value = true },
        icon: 'PlusCircleIcon',
      },
    ])
    const filters = ref([])
    return {
      // Ref
      refWorkAreaList,
      tableColumns,
      isAddNewWorkAreaSidebarActive,
      tableActions,
      actions,
      filters,
      // API Calls
      fetchWorkAreas,
      fetchWorkArea,
      deleteWorkArea,
    }
  },
}
</script>
<style lang="scss" scoped>

</style>
